import './App.css';
import { assetsImages } from './assets/Images';

function App() {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <h1>HEALTH HARVEST</h1>
      </div>
      <div>
        <img src={assetsImages.img1} />
      </div>
      <div>
        <img src={assetsImages.img2} />
      </div>
      <div>
        <img src={assetsImages.img3} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <h1>Stay tuned for further updates!!!</h1>
      </div>
    </div>
  );
}

export default App;
